import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "ar",
};

export const langSlice = createSlice({
  name: "Lang",
  initialState,
  reducers: {
    setLanguage: (state, action) => ({
      ...state,
      lang: action.payload,
    }),
    resetState: () => ({
      lang: "ar",
    }),
  },
});

export const { setLanguage, resetState } = langSlice.actions;

export const langReducer = langSlice.reducer;
