import { useTranslation } from "react-i18next";
import diamond_icon from "../../assets/images/diamond_icon.png";
import { Fade, Reveal, Zoom } from "react-awesome-reveal";

const FinanceSection = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="finance_details_wrapper">
        <div className="finance_details_Diamond_box">
          <Zoom delay={100} triggerOnce>
            <Fade triggerOnce>
              <div className="card_white_shadow" />
              <div className="card_image">
                <img src={diamond_icon} alt="diamond_icon" />
              </div>
            </Fade>
          </Zoom>
        </div>
        <div className="finance_details_box">
          <div className="finance_details_title_box">
            <div className="finance_details_title_Desc">
              <Fade direction={"up"} cascade damping={0.6} triggerOnce>
                <div className={`title`}>{t("finance_details_title")}</div>
                <div className={`description`}>
                  {" "}
                  {t("finance_details_description")}
                </div>
              </Fade>
            </div>
          </div>
          <Reveal triggerOnce className="finance_details_inner">
            <div className="finance_details_inner_box">
              <div className="text_box_1">
                {t("finance_details_inner_text1")}
              </div>
              <div className="text_box_2">
                {t("finance_details_inner_text2")}
              </div>
              <div className="text_box_3">
                {t("finance_details_inner_text3")}
              </div>
            </div>
          </Reveal>
        </div>
      </div>
      <div className="finance_description_wrapper" id="steps_section">
        <Fade delay={100} cascade damping={0.6} triggerOnce>
          <div className={`title`}>{t("finance_desc_title")}</div>
          <div className={`description`}>{t("finance_desc_description")}</div>
        </Fade>
      </div>
    </div>
  );
};

export default FinanceSection;
