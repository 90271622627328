import { useTranslation } from "react-i18next";
import { steps_cards } from "../../utils/constant";
import useLanguage from "../../hooks/useLanguage";
import { Fade } from "react-awesome-reveal";

const StepsSection = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const renderCards = () => {
    return (
      steps_cards &&
      steps_cards.length > 0 &&
      steps_cards.map((v, i) => {
        return (
          <div key={i} className="card_box col-md-4 col-xl-2">
            <div className="card_white_shadow" />
            <div className="card_inner_box">
              <div className="image_box">
                <img src={v.image} className="image" alt="" />
              </div>
              <div className={`title`}>
                {language === "ar" ? v.title_ar : v.title_en}
              </div>
            </div>{" "}
          </div>
        );
      })
    );
  };

  return (
    <Fade
      delay={100}
      cascade
      damping={0.8}
      triggerOnce
      className="container steps_cards_wrapper"
    >
      <div className={`title`}>{t("grid_cards_title")}</div>
      <div className="card_wrapper">
        <div className="card_boxes row gap-6">{renderCards()}</div>
      </div>
    </Fade>
  );
};

export default StepsSection;
