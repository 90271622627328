export const MainLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 500 600"
    style="enable-background:new 0 0 500 600;"
  >
    <g>
      <g>
        <g>
          <path className="st1" d="M193.26,355.3v-95.49h11.93v95.49H193.26z" />
          <path
            className="st1"
            d="M224.82,355.3v-11.11h37.08v-1.9c0-13.69-10.44-26.83-26.29-37l7.32-9.62
				c11.79,7.72,19.78,15.45,24.26,23.31c4.47,8,6.64,17.07,6.64,27.24v9.08H224.82z"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M216.54,370.22v52.56h-6.53v-4.94h-0.15c-2.54,3.7-7.11,5.81-11.98,5.81c-5.08,0-9.29-1.67-12.56-5.08
				c-3.27-3.34-4.94-7.7-4.94-13c0-5.3,1.67-9.66,4.94-13.07c3.27-3.34,7.48-5.01,12.56-5.01c4.87,0,9.58,2.25,11.98,5.81h0.15
				v-23.09H216.54z M187.36,405.58c0,3.56,1.02,6.46,3.12,8.64c2.1,2.25,4.79,3.34,8.2,3.34c6.75,0,11.32-4.79,11.32-11.98
				c0-7.26-4.65-11.98-11.4-11.98c-3.34,0-6.03,1.09-8.13,3.27C188.38,399.12,187.36,402.02,187.36,405.58z"
          />
          <path
            className="st1"
            d="M252.35,390.84c2.4,2.25,3.56,4.86,3.56,7.91v18.44c0,2.1,0.07,3.99,0.29,5.59h-5.81
				c-0.15-1.74-0.22-3.49-0.22-5.23h-0.15c-2.69,4.07-6.75,6.1-12.05,6.1c-6.97,0-11.9-3.99-11.9-10.31
				c0-7.33,6.02-11.91,18.88-11.91h4.86v-1.23c0-4.57-3.34-7.48-8.28-7.48c-3.78,0-5.95,0.94-9.73,3.92l-3.92-4.06
				c3.7-3.41,8.2-5.08,13.65-5.08C246.32,387.5,249.95,388.59,252.35,390.84z M240.01,418.43c6.02,0,9.8-3.7,9.8-10.31v-1.89h-3.7
				c-9,0-13.5,2.18-13.5,6.61C232.6,416.54,235.07,418.43,240.01,418.43z"
          />
          <path className="st1" d="M273.78,370.22v52.56h-6.54v-52.56H273.78z" />
        </g>
        <path
          className="st1"
          d="M161.73,259.81v82.21c0,16.26-8.4,24.39-25.07,24.39H125.1v11.52h11.56v-0.02c22.77-0.24,37-12.38,37-32.78
			v-85.32H161.73z"
        />
      </g>
    </g>
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="258.6537"
      y1="270.1939"
      x2="351.157"
      y2="177.6907"
    >
      <stop offset="0" style="stop-color:#B5F704" />
      <stop offset="1" style="stop-color:#68AA10" />
    </linearGradient>
    <path
      className="st0"
      d="M351.49,180.42l-35.51-17.02l-9.79,20.42l-21.07-10.1c-9.56-4.58-20.63-3.95-29.6,1.7
	c-8.97,5.65-14.33,15.35-14.33,25.95v38.92c0,11.53,6.72,22.19,17.11,27.18l35.51,17.02l9.79-20.42l21.07,10.1
	c4.23,2.03,8.76,3.04,13.28,3.04c5.67,0,11.32-1.59,16.32-4.74c8.97-5.65,14.33-15.35,14.33-25.95V207.6
	C368.6,196.07,361.89,185.4,351.49,180.42z M345.6,246.52c0,3.82-2.5,5.81-3.58,6.49c-1.08,0.68-3.95,2.08-7.4,0.43l-21.5-10.31
	l-9.79,20.42l-35.08-16.82c-2.46-1.18-4.05-3.71-4.05-6.44v-38.92c0-3.82,2.5-5.81,3.58-6.49s3.95-2.08,7.4-0.43l21.5,10.31
	l9.79-20.42l35.08,16.82c2.46,1.18,4.05,3.71,4.05,6.44V246.52z"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16992 14.8299L14.8299 9.16992"
      stroke="#094424"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.8299 14.8299L9.16992 9.16992"
      stroke="#094424"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke="#094424"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
