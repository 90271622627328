import React, { useEffect, useState } from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import HeroSection from "./components/Home/hero_section";
import FinanceSection from "./components/Home/finance_section";
import StepsSection from "./components/Home/steps_section";
import MobileSection from "./components/Home/mobile_section";
import useLanguage from "./hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { FullScreenLoader } from "./components/common/loader";

function App() {
  const { i18n } = useTranslation();
  const { language } = useLanguage();

  useEffect(() => {
    const loadLanguageResources = async () => {
      switch (language) {
        case "ar":
          i18n.changeLanguage("ar");
          break;
        case "en":
          i18n.changeLanguage("en");
          break;
        default:
          break;
      }
    };

    if (language) {
      loadLanguageResources();
    }
  }, [language, i18n]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStyle = async () => {
      if (language === "ar") {
        await import("./assets/scss/ar.scss");
      } else if (language === "en") {
        await import("./assets/scss/en.scss");
      }
      setTimeout(() => {
        setLoading(false);
      }, 300);
    };

    loadStyle();
  }, [language, i18n]);

  return (
    <>
      {loading && <FullScreenLoader />}
      <Navbar />
      <div>
        <HeroSection />
        <FinanceSection />
        <StepsSection />
        <MobileSection />
      </div>
      <Footer />
    </>
  );
}

export default App;
