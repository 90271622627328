import { CheckmarkIcon } from "react-hot-toast";

export default function Checkbox({
  active = false,
  setActive = () => {},
  label = "",
  error = false,
}) {
  return (
    <div
      className="d-flex gap-2 w-fit my-4"
      onClick={() => setActive(!active)}
      role="button"
    >
      <div
        className={`custom-checkbox ${active && "active"} ${
          error && "border-danger"
        }`}
      >
        {active && <CheckmarkIcon />}
      </div>
      <p className={error && "text-danger"}>{label || ""}</p>
    </div>
  );
}
