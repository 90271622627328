import { useTranslation } from "react-i18next";
import hero_image from "../../assets/images/mobile_section_image.png";
import { Fade } from "react-awesome-reveal";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toast,
} from "react-bootstrap";
import { useState } from "react";
import Input from "../common/input";
import { extractError, filterNumbers, Validator } from "../../utils/helper";
import { ButtonLoader } from "../common/loader";
import { UserService } from "../../services/userService";
import toast from "react-hot-toast";
import { CloseIcon } from "../../assets/svg";
import Checkbox from "../common/checkbox";

export default function MobileSection() {
  const { t } = useTranslation();

  /* Modal */
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* form */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isApproved, setIsApproved] = useState(false);

  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [isApprovedErr, setIsApprovedErr] = useState(false);

  const [loading, setLoading] = useState("");

  const validate = () => {
    if (!firstName.trim()) {
      setFirstNameErr(t("mobile_section_form_input_firstname_err"));
      return false;
    }
    if (!lastName.trim()) {
      setLastNameErr(t("mobile_section_form_input_lastname_err"));
      return false;
    }
    if (!email.trim()) {
      setEmailErr(t("mobile_section_form_input_email_err"));
      return false;
    }
    if (!Validator.isEmail(email)) {
      setEmailErr(t("mobile_section_form_input_email_err_validate"));
      return false;
    }
    if (!phoneNumber.trim()) {
      setPhoneNumberErr(t("mobile_section_form_input_phone_err"));
      return false;
    }
    if (!Validator.isMobilePhone(phoneNumber)) {
      setPhoneNumberErr(t("mobile_section_form_input_phone_err_valid"));
      return false;
    }
    if (!isApproved) {
      setIsApprovedErr(true);
      return false;
    }

    return true;
  };

  const clearStates = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
  };

  const handleSubmit = async () => {
    try {
      if (validate()) {
        setLoading(true);
        let params = {
          firstName,
          lastName,
          email,
          phoneNumber,
        };
        UserService.registerUser(params).then((res) => {
          if (res.statusCode) {
            toast.success(t("mobile_section_form_success"), {
              duration: 10000,
            });
            clearStates();
            handleClose();
          } else {
            let err = extractError(res);
            toast.error(
              err == "Already registered"
                ? t("mobile_section_form_already_registered")
                : err,
              {
                duration: 5000,
              }
            );
          }
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      {/* Modal */}
      <Modal
        show={open}
        onBackdropClick={handleClose}
        onEscapeKeyDown={handleClose}
        onHide={handleClose}
        className="common-modal"
        size="lg"
        centered
      >
        <ModalHeader>
          <div className="d-flex justify-content-between w-100 px-1 py-0">
            <p className="title py-0">{t("mobile_section_button")}</p>
            <div role="button" onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Input
              label={t("mobile_section_form_input_firstname")}
              value={firstName}
              onChange={(e) => (
                setFirstName(e.target.value), setFirstNameErr("")
              )}
              error={firstNameErr}
            />
            <Input
              label={t("mobile_section_form_input_lastname")}
              value={lastName}
              onChange={(e) => (
                setLastName(e.target.value), setLastNameErr("")
              )}
              error={lastNameErr}
            />
            <Input
              label={t("mobile_section_form_input_email")}
              value={email}
              onChange={(e) => (setEmail(e.target.value), setEmailErr(""))}
              error={emailErr}
            />
            <Input
              label={t("mobile_section_form_input_phone")}
              value={phoneNumber}
              onChange={(e) => (
                setPhoneNumber(filterNumbers(e.target.value)),
                setPhoneNumberErr("")
              )}
              error={phoneNumberErr}
            />

            <Checkbox
              active={isApproved}
              setActive={setIsApproved}
              label={t("mobile_section_form_checkbox_text")}
              error={isApprovedErr}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              className="btn btn-primary px-4"
              onClick={handleSubmit}
              disabled={loading}
            >
              {t("mobile_section_form_button")}
              {loading && <ButtonLoader />}
            </button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Main */}
      <section className="mobile_section_wrapper">
        <div className="content_container col-xl-5">
          <Fade direction={"up"} cascade damping={0.8} triggerOnce>
            <div className="title">{t("mobile_section_title")}</div>
            <div className="subtitle">{t("mobile_section_subtitle")}</div>
            <button
              className="btn btn-primary my-4 px-4 fw-bold"
              onClick={handleOpen}
            >
              {t("mobile_section_button")}
            </button>
          </Fade>
        </div>
        <div className="hero_image">
          <img src={hero_image} alt="hero_image" />
        </div>
      </section>
      {/* Modal */}
      <Modal />
    </div>
  );
}
