import PrimaryLogo from "../assets/images/logo.png";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLanguage } from "../redux/features/slices/langSlice";
import useLanguage from "../hooks/useLanguage";
import { Fade, Flip } from "react-awesome-reveal";
import { FullScreenLoader } from "./common/loader";
import useScrollTop from "../hooks/useScrollTop";

export default function Navbar() {
  const { language } = useLanguage();

  const dispatch = useDispatch();

  useScrollTop();

  const [activeLanguage, setActiveLanguage] = useState("");
  const [loading, setLoading] = useState(false);

  const changeLanguage = () => {
    setLoading(true);
    const newLanguage = language === "ar" ? "en" : "ar";

    setTimeout(() => {
      dispatch(setLanguage(newLanguage));
      setTimeout(() => {
        window.location.reload();
      }, 5);
    }, 300);
  };

  useEffect(() => {
    setActiveLanguage(language === "ar" ? "EN" : "AR");
  }, [language]);

  return (
    <>
      {loading && <FullScreenLoader />}
      <nav className="container">
        <Fade cascade damping={0.6} triggerOnce>
          <div className="nav_wrapper">
            <img src={PrimaryLogo} className="logo" alt="logo" />
            <div className="locals" onClick={changeLanguage}>
              <Flip triggerOnce>{activeLanguage}</Flip>
            </div>
          </div>
        </Fade>
      </nav>
    </>
  );
}
