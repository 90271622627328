/* i18n */
import "./i18n";

/* Redux */
import { Provider } from "react-redux";
import store from "./redux/store";

/* Bootstrap */
import "bootstrap/dist/css/bootstrap.css";

/* SCSS */
import "./assets/scss/_classes.scss";
import "./assets/scss/_colors.scss";
import "./assets/scss/_fonts.scss";
import "./assets/scss/_mixins.scss";
import "./assets/scss/_normalize.scss";
import "./assets/scss/_variables.scss";
import "./assets/scss/app.scss";
import "./assets/scss/media.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Toaster />
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
