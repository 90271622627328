import { useTranslation } from "react-i18next";
import { BsTwitterX, BsWhatsapp } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="container footer_section_wrapper">
      <div className="description">
        {t("footer_text").replace("year", new Date().getFullYear())}
      </div>
      <div className="social_icons my-4 mx-2">
        <Fade cascade damping={0.8} triggerOnce>
          <a className="button" href="https://facebook.com" target="_blank" rel="noreferrer">
            <BsTwitterX />
          </a>
          <a className="button" href="https://linkedin.com" target="_blank" rel="noreferrer">
            <FaLinkedinIn />
          </a>
          <a className="button" href="https://web.whatsapp.com" target="_blank" rel="noreferrer">
            <BsWhatsapp />
          </a>
        </Fade>
      </div>
    </footer>
  );
}
