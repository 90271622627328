import Validator from "validator";

export { Validator };

const grid_breakpoints = {
  xs: 0,
  sm: 576, // mobile
  md: 768, // tablet
  lg: 992, // tablet
  xl: 1200, // small monitor
  xxl: 1400, // large monitor
};

export const isMobile =
  typeof window !== "undefined" && window.innerWidth < grid_breakpoints.lg;

export const extractError = (res) => {
  return res.error[0].responseMessage;
};

export const getLink = (link) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `http://${link}`;
};

export const filterNumbers = (str) => {
  return str.replace(/[a-zA-Z]/g, "").trim();
};

export const filterString = (str) => {
  return str.replace(/\d/g, "");
};

export function generateRandomId() {
  const min = 10000000000000;
  const max = 99999999999999;

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const scrollToTop = () => {
  return window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const getCurrentRoute = () => {
  return (
    window &&
    typeof window !== undefined &&
    window.location.pathname.split("/").pop()
  );
};
