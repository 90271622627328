import axios from "axios";
import { config } from "../config";
// import { BrowserUtility } from "../browserUtility";

const onSuccess = (response) => {
  console.debug("Request Successful!", response);
  return response.data;
};

const onError = async (error) => {
  console.debug("Request Failed:", error);

  console.debug("Request Failed:", error.config);

  if (error.response) {
    if (error.response.status === 401) {
      // handleLogoutUser();
    }
    console.debug("Status:", error.response.status);
    console.debug("Data:", error.response.data);
    console.debug("Headers:", error.response.headers);
  }

  return Promise.resolve({
    error: error.response.data.error || error.response.data,
    status: error.response.status,
  });
};

const request = async (options) => {
  let headers = {};

  // let authToken = BrowserUtility.get("authToken");
  // if (authToken) {
  //   headers["Authorization"] = authToken && `Bearer ${authToken}`;
  // }

  // let localUser = BrowserUtility.get("user");
  // if (localUser) {
  //   headers["user"] = BrowserUtility.get("user");
  // }

  const client = axios.create({
    baseURL: config.BASE_URL,
    headers: { ...headers },
  });

  return client(options).then(onSuccess).catch(onError);
};

export class ApiInterceptor {
  static get = (url, data) => {
    return request({
      url,
      method: "GET",
      data,
    });
  };

  static delete = (url, data) => {
    return request({
      url,
      method: "DELETE",
      data,
    });
  };

  static post = (url, data) => {
    return request({
      url,
      method: "POST",
      data,
    });
  };

  static put = (url, data) => {
    return request({
      url,
      method: "PUT",
      data,
    });
  };

  static extenralAPICall = (url) => {
    const client = axios({
      url,
      method: "GET",
      timeout: 1000 * 3,
    });
    return client.then(onSuccess).catch(onError);
  };
}
