import step1_image from "../../assets/images/avatar_icon.png";
import step2_image from "../../assets/images/gallery_icon.png";
import step3_image from "../../assets/images/accept_icon.png";
import step4_image from "../../assets/images/currency_icon.png";

export const Locals = {
  ar: "AR",
  en: "EN",
};

export const steps_cards = [
  {
    id: 1,
    image: step1_image,
    title_ar: "التسجيل في المنصة",
    title_en: "Register",
  },
  {
    id: 2,
    image: step2_image,
    title_ar: "استعراض الخيارات",
    title_en: "Browse your options",
  },
  {
    id: 3,
    image: step3_image,
    title_ar: "اختيار العرض المناسب",
    title_en: "Select your option",
  },
  {
    id: 4,
    image: step4_image,
    title_ar: "الحصول على التمويل",
    title_en: "Obtaining  financing",
  },
];
