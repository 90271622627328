import { APIPath } from "../utils/api";
import { ApiInterceptor } from "../utils/api/ApiInterceptor";

class User {
  registerUser = (params) => {
    return ApiInterceptor.post(APIPath.REGISTER_USER, params);
  };
}

const UserService = new User();

Object.freeze(UserService);

export { UserService };
