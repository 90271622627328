import React from "react";

export default function Input({ error = "", label = "", ...props }) {
  return (
    <div className={`w-full form-input custom-input mt-3`}>
      <input
        type="text"
        placeholder=""
        className={`${error && "border-danger"}`}
        {...props}
      />
      <label className="placeholder">{label}</label>
      {error !== "" && <p className="text-danger">{error}</p>}
    </div>
  );
}
